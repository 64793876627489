$(document).ready(function () {
  $('html').removeClass();
  $('button.menu-toggle').on('click', function () {
    if (!$('.responsive-nav-wrapper').hasClass('active')) {
      $('.responsive-nav-wrapper').addClass('active');
      $('.navbar-toggler-icon .fas').removeClass('fa-bars');
      $('.navbar-toggler-icon .fas').addClass('fa-window-close');
    } else {
      var windowHeight = $(window).innerHeight();
      $('.responsive-nav-wrapper').removeClass('active');
      $('.navbar-toggler-icon .fas').addClass('fa-bars');
      $('.navbar-toggler-icon .fas').removeClass('fa-window-close');
    }
  });

  //Menu with overlay
  var overlay = $('#menu-overlay');
  var screen = $(window);
  var aankoop_link = $('li.aankoop-link-item');
  var taxatie_link = $('li.taxatie-link-item');
  var woningaanbod_link = $('li.woningaanbod-link-item');
  var over_link = $('li.over-link-item');
  var verkoop_link = $('li.verkoop-link-item');
  var contact_link = $('li.contact-link-item');
  var hypotheken_link = $('li.hypotheken-link-item');

  // Desktop menu
  if (screen.width() > 991) {
    aankoop_link.hover(
      function () {
        overlay.addClass("show");
        if (aankoop_link.hasClass("open")) {
          return false;
        } else {
          aankoop_link.addClass("open");
        }
      },
      function () {
        overlay.removeClass("show");
        if (aankoop_link.hasClass("open")) {
          aankoop_link.removeClass("open");
        } else {
          return false;
        }
      });
    taxatie_link.hover(
      function () {
        overlay.addClass("show");
        if (taxatie_link.hasClass("open")) {
          return false;
        } else {
          taxatie_link.addClass("open");
        }
      },
      function () {
        overlay.removeClass("show");
        if (taxatie_link.hasClass("open")) {
          taxatie_link.removeClass("open");
        } else {
          return false;
        }
      });
    woningaanbod_link.hover(
      function () {
        overlay.addClass("show");
        if (woningaanbod_link.hasClass("open")) {
          return false;
        } else {
          woningaanbod_link.addClass("open");
        }
      },
      function () {
        overlay.removeClass("show");
        if (woningaanbod_link.hasClass("open")) {
          woningaanbod_link.removeClass("open");
        } else {
          return false;
        }
      });
    over_link.hover(
      function () {
        overlay.addClass("show");
        if (over_link.hasClass("open")) {
          return false;
        } else {
          over_link.addClass("open");
        }
      },
      function () {
        overlay.removeClass("show");
        if (over_link.hasClass("open")) {
          over_link.removeClass("open");
        } else {
          return false;
        }
      });
    verkoop_link.hover(
      function () {
        overlay.addClass("show");
        if (verkoop_link.hasClass("open")) {
          return false;
        } else {
          verkoop_link.addClass("open");
        }
      },
      function () {
        overlay.removeClass("show");
        if (verkoop_link.hasClass("open")) {
          verkoop_link.removeClass("open");
        } else {
          return false;
        }
      });
    contact_link.hover(
      function () {
        overlay.addClass("show");
        if (contact_link.hasClass("open")) {
          return false;
        } else {
          contact_link.addClass("open");
        }
      },
      function () {
        overlay.removeClass("show");
        if (contact_link.hasClass("open")) {
          contact_link.removeClass("open");
        } else {
          return false;
        }
      });
    hypotheken_link.hover(
      function () {
        overlay.addClass("show");
        if (hypotheken_link.hasClass("open")) {
          return false;
        } else {
          hypotheken_link.addClass("open");
        }
      },
      function () {
        overlay.removeClass("show");
        if (hypotheken_link.hasClass("open")) {
          hypotheken_link.removeClass("open");
        } else {
          return false;
        }
      });
  }

  // Sticky menu on single woning pages
  $(window).scroll(function () {
    $footerTop = $('.pre-footer').offset().top;
    if ($(window).scrollTop() > 250 && ($(window).scrollTop() < $footerTop - 250)) {
      $('.stick-menu').addClass('animate-show');
    } else {
      $('.stick-menu').removeClass('animate-show');
    }
  });

  $('.menu-item a i').on('click', function (event) {
    event.preventDefault();
    //$('#nav ul.sub-menu').slideUp();
    /*$(this).html(function (i, text) {
        return text === '<i class="fas fa-chevron-up"></i>' ? '<i class="fas fa-chevron-down"></i>' : '<i class="fas fa-chevron-up"></i>';
    });*/
    $(this).parent().parent().find('ul.sub-menu').slideToggle();

  });
});