jQuery(document).ready(function ($) {
  $('.slick-intro').slick({
    infinite: true,
    arrows: true,
    dots: true,
    cssEase: 'ease-in-out',
    variableWidth: false,
    prevArrow: $('.intro-vorige'),
    nextArrow: $('.intro-volgende'),
    appendDots: $('.intro-dots'),
    slidesToShow: 1
  });
});