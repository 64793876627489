jQuery(document).ready(function ($) {
  $('.slick-woning-lijst').slick({
    infinite: false,
    arrows: true,
    dots: false,
    cssEase: 'ease-in-out',
    variableWidth: false,
    prevArrow: $('.woning-lijst-vorige'),
    nextArrow: $('.woning-lijst-volgende'),
    appendDots: $('.woning-lijst-dots'),
    slidesToShow: 4,
    responsive: [{
        breakpoint: 1600,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true
        }
      }
    ],
    focusOnSelect: true,
  });
});