import LazyLoad from "vanilla-lazyload";

const elements = document.querySelectorAll("img, iframe, .lazy, video, source");
const createLazyLoadInstance = () => {
	return new LazyLoad({
    unobserve_entered: true,
    webp: true,
  }, 
  elements
  );
};

const delayedCreateLazyLoadInstance = () => {
  setTimeout(createLazyLoadInstance, 100);
}

document.addEventListener("DOMContentLoaded", createLazyLoadInstance);
window.lazyLoad = () => {
  console.log('lazyloading');
  const elements = document.querySelectorAll("img, iframe, .lazy, video, source");

	return new LazyLoad({
    unobserve_entered: false,
    webp: true,
  }, 
  elements
  );
};

