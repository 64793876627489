const forms = [...document.querySelectorAll('form')];
forms.forEach((form) => {

  if (form.addEventListener) {
    form.addEventListener('submit', () => {
      if ( form.querySelector('button') ) form.querySelector('button').classList.add('disabled');
      if ( form.querySelector('button') ) form.querySelector('button').setAttribute('disabled', true);
      if ( form.querySelector('input[type="button"]') ) form.querySelector('input[type="button"]').classList.add('disabled');
      if ( form.querySelector('input[type="button"]') ) form.querySelector('input[type="button"]').setAttribute('disabled', true);
    });

    const inputs = [...form.querySelectorAll('input')];
    inputs.forEach( (input) => {
      input.addEventListener( 'keyup', () => {
        if ( form.querySelector('button') ) form.querySelector('button').classList.remove('disabled');
        if ( form.querySelector('button') ) form.querySelector('button').removeAttribute('disabled');
        if ( form.querySelector('input[type="button"]') ) form.querySelector('input[type="button"]').classList.remove('disabled');
        if ( form.querySelector('input[type="button"]') ) form.querySelector('input[type="button"]').removeAttribute('disabled');
      })
    })

  } else if (form.attachEvent) {
    form.attachEvent('onsubmit', () => {
      if ( form.querySelector('button') ) form.querySelector('button').classList.add('disabled');
      if ( form.querySelector('button') ) form.querySelector('button').setAttribute('disabled', true);
      if ( form.querySelector('input[type="button"]') ) form.querySelector('input[type="button"]').classList.add('disabled');
      if ( form.querySelector('input[type="button"]') ) form.querySelector('input[type="button"]').setAttribute('disabled', true);
    })

    const inputs = [...form.querySelectorAll('input')];
    inputs.forEach( (input) => {
      input.attachEvent( 'onkeyup', () => {
        if ( form.querySelector('button') ) form.querySelector('button').classList.remove('disabled');
        if ( form.querySelector('button') ) form.querySelector('button').removeAttribute('disabled');
        if ( form.querySelector('input[type="button"]') ) form.querySelector('input[type="button"]').classList.remove('disabled');
        if ( form.querySelector('input[type="button"]') ) form.querySelector('input[type="button"]').removeAttribute('disabled');
      })
    })
  }

});