jQuery(document).ready(function ($) {

  jQuery('.pp_like').on( 'click', function (e) {
      e.preventDefault();
      jQuery('.pp_like').hide();
      var postid = jQuery(this).data('id');
      var data = {
          action: 'toggle_like',
          security: MyAjax.security,
          postid: postid
      };
      jQuery.post(MyAjax.ajaxurl, data, function (res) {
          var result = JSON.parse(res);
          var likes = parseInt(jQuery('.pp_like_count').text());

          likes = result.likecount;
          jQuery('.pp_like_count').text(likes);

          if (result.like == 1) {
              jQuery('.pp_like').addClass('liked');
              if (jQuery('.dislike').hasClass('active')) {
                  jQuery('.dislike').removeClass('active');
              }
          }
          if (result.dislike == 1) {
              jQuery('.pp_like').removeClass('liked');

          }
          jQuery('.pp_like').show();
      });
  });

  jQuery('.dislike').on( 'click', function (e) {
      e.preventDefault();
      jQuery('.pp_like.btn').hide();
      if ( jQuery('.pp_like').hasClass('liked')) {
          jQuery('.pp_like').removeClass('liked');
      }
      if (jQuery(this).hasClass('active')) {
          jQuery(this).removeClass('active');
      } else {
          jQuery(this).addClass('active');
      }
      var postid = jQuery(this).data('id');
      var data = {
          action: 'dislike',
          security: MyAjax.security,
          postid: postid
      };
      jQuery.post(MyAjax.ajaxurl, data, function (res) {
          var result = JSON.parse(res);
          var likes = parseInt(jQuery('.pp_like_count').text());
          likes = result.likecount;
          jQuery('.pp_like_count').text(likes);

          if (result.dislike == 1) {
              jQuery('.pp_like').removeClass('liked');
          }
      });
  });
});