import crumbs from "crumbsjs";

const gtmId = document.head.querySelector("[name=gtm]")
  ? document.head.querySelector("[name=gtm]").content
  : "";

window.addEventListener("load", (e) => {
  initGtm();
});

function initGtm() {
  if (window.gtmDidInit) return false;
  if (gtmId === "" || gtmId === null || gtmId === undefined) return false;
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
    var f = d.getElementsByTagName("meta")[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.id = "gtm-init";
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
    j.onload = async () => {
      window.gtmDidInit = true;
      startGtm();
    };
  })(window, document, "script", "dataLayer", gtmId);
}

function startGtm() {
  dataLayer.push({
    event: "gtm.js",
    "gtm.start": new Date().getTime(),
    "gtm.uniqueEventId": 0,
  });
}
