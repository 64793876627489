jQuery(document).ready(function ($) {
  $('.slick-medewerkers').slick({
    infinite: false,
    arrows: true,
    dots: true,
    cssEase: 'ease-in-out',
    variableWidth: true,
    lazyLoad: 'ondemand',
    prevArrow: $('.mw-vorige'),
    nextArrow: $('.mw-volgende'),
    appendDots: $('.mw-dots'),
    slidesToShow: 4,
    responsive: [
        { 
            breakpoint: 1200,
            settings: {
                slidesToShow: 3
            }
        },
        { 
            breakpoint: 768,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                centerMode: true
            }
        }
    ]

});
});