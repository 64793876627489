/**
 * Helpers
 */
import { onVisibilityChange } from './helpers'
import Accordion from 'accordion-js';


global.jQuery = require('jquery');
window.jQuery = require('jquery');
require('webpack-jquery-ui');
require('webpack-jquery-ui/css');
require("jquery-ui/ui/widgets/autocomplete");
require("jquery-ui/ui/widgets/draggable");
require("jquery-ui/ui/widgets/selectmenu");
require("jquery-ui/ui/widgets/slider");

/**
 * Web standards
 */
import './lazyload'
import './cookieconsent'

/**
 * Scripts which doesn't need dynamic import
 */
import { Modal, Collapse } from 'bootstrap';
import './plugins/slick';
import './plugins/counto';
import './menu';
import './artikelen.slick';
import './medewerkers.slick';
import './referenties.slick';
import './woninglijst.slick';
import './woningmedia.slick';
import './woningplattegrond.slick';
import './backtotop';
import './counters';
import './critical';
import './intro.slick';
import './postlike';
import './forms';

/**
 * Dynamic import scripts
 */
const elements = [
  '#faq',
];

[].forEach.call(elements, (element) => {


  if (document.querySelector(element) !== null && document.querySelector(element) !== undefined) {

    const lazyloadHandler = onVisibilityChange(document.querySelector(element), function () {

      if (element === '#faq') {
        const acc = new Accordion('#faq', {
          duration: 300, 
          ariaEnabled: true, 
          collapse: true, 
          showMultiple: false, 
          openOnInit: [], 
          elementClass: 'block__faq__accordion__item', 
          triggerClass: 'block__faq__accordion__button', 
          panelClass: 'block__faq__accordion__panel', 
          activeClass: 'collapsed'
        });
      }
      

    })

    if (window.addEventListener) {
      addEventListener('DOMContentLoaded', lazyloadHandler, false);
      addEventListener('load', lazyloadHandler, false);
      addEventListener('scroll', lazyloadHandler, false);
      addEventListener('resize', lazyloadHandler, false);
    } else if (window.attachEvent) {
      attachEvent('onDOMContentLoaded', lazyloadHandler); // Internet Explorer 9+ :(
      attachEvent('onload', lazyloadHandler);
      attachEvent('onscroll', lazyloadHandler);
      attachEvent('onresize', lazyloadHandler);
    }
  }
});

(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
vgo('setAccount', '610212506');
vgo('setTrackByDefault', true);vgo('process');

$( ".medewerkers-lijst .medewerkers-wrapper:odd" ).addClass('odd');
$( ".medewerkers-lijst .medewerkers-wrapper:even" ).addClass('even');  

document.querySelectorAll('[aria-hidden="true"]').forEach(hiddenElem => {
  hiddenElem.querySelectorAll('a, button, input, textarea, select, details, [tabindex]').forEach(focusableElem => {
      focusableElem.setAttribute('tabindex', '-1');
  });
});