jQuery(document).ready(function ($) {
  $('.slick-referenties').slick({
    infinite: false,
    arrows: true,
    dots: true,
    cssEase: 'ease-in-out',
    variableWidth: true,
    slidesToShow: 3,
    prevArrow: $('.ref-vorige'),
    nextArrow: $('.ref-volgende'),
    appendDots: $('.ref-dots'),
    responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true
        }
      }
    ]
  });

});