// Back to top
jQuery(document).ready(function ($) {
  var btn = $('#back_to_top');
  var windowHeight = $(document).height();

  var stopShowOnHeight = windowHeight - 300;

  $(window).scroll(function () {
    if ($(window).scrollTop() > 300) {
      btn.addClass('show');
    }
    if ($(window).scrollTop() > stopShowOnHeight) {
      btn.removeClass('show');
    }
    if ($(window).scrollTop() < 300) {
      btn.removeClass('show');
    }
  });

  btn.on('click', function (e) {
    e.preventDefault();
    $('html, body').animate({
      scrollTop: 0
    }, '300');
  });
});