jQuery(document).ready(function ($) {
  $('#slick-media-woning').slick({
    infinite: false,
    arrows: true,
    dots: true,
    speed: 500,
    fade: true,
    cssEase: 'ease-in-out',
    variableWidth: false,
    prevArrow: $('.wm-vorige'),
    nextArrow: $('.wm-volgende'),
    appendDots: $('.media-woning-dots'),
    slidesToShow: 3,
    lazyLoad: 'progressive',
    responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false
        }
      }
    ],
    focusOnSelect: false
  });
});