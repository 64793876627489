
var o = $('.count');
var cc = 1;
if (o.length > 0) {
  $(window).scroll(function () {
    var elemPos = o.offset().top;
    var elemPosBottom = o.offset().top + o.height();
    var winHeight = $(window).height();
    var scrollToElem = elemPos - winHeight;
    var winScrollTop = $(window).scrollTop() + 1;
    if (winScrollTop > scrollToElem) {
      if (elemPosBottom > winScrollTop) {
        if (cc < 2) {
          cc = cc + 2;
          $(document).ready(function () {
            o.countTo({
              formatter: function (value, options) {
                value = value.toFixed(options.decimals);
                if (value.length = 4) {
                  value = value.replace(/,/g, '.');
                } else {
                  value = value.replace(/./g, ',');
                }
                return value;
              }
            });
          });
        }
      }
    }
  });
}